import { Component, OnInit } from '@angular/core';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  cms: any;

  constructor(private https: HttpsService,) { }

  ngOnInit(): void {
    this.getCms();
  }

  getCms(){
    this.https.httpGetWithHeader('cms').subscribe((res: any) => {
      if(res['status']==200){
        this.cms=res['body']['data']

      }
    })
  }
}
