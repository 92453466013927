import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanDeactivate,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  userData: any;
  access: boolean;
  constructor(private router: Router) {

    if (localStorage.getItem('akunamatata_web_user')) {
      this.userData = JSON.parse(localStorage.getItem('akunamatata_web_user'))
      if(this.userData.isPhone == true){
        if (this.userData.user.isPhoneVerified == false) {
          this.access = false
        }
      }else if(this.userData.isEmail ==true){
         if(this.userData.user.isEmailVerified == false){
          this.access=false
        }
      }else
      {
        if (this.userData?.user?.isEmailVerified == false && this.userData?.user?.isPhoneVerified == false) {
          this.access = false
        }
      }
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (next.routeConfig.path === 'pages/home') {
      if(this.access==false){
        localStorage.clear();
        return true
      }else {
        return true;
      }
    } else if(next.routeConfig.path === 'pages'){
      if(this.access==false){
        localStorage.clear();
        return true
      }else {
        return true;
      }
    }
  }
}
