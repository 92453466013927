import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PhoneOtpComponent } from '../phone-otp/phone-otp.component';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  constructor(public modalRef: BsModalRef, private modalService: BsModalService) { }

  ngOnInit(): void {
  }
  openPhoneotp() {
    this.modalRef.hide();
    this.modalService.show(PhoneOtpComponent, { class : 'modal-lg modal-dialog-centered auth-element' });
  }
}
