import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { MustMatch } from 'src/app/services/must-match.validators';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {

  submitted: boolean;
  changeForm: FormGroup;
  constructor(public modalRef: BsModalRef, private modalService: BsModalService,private router: Router,
    private https: HttpsService,
    private fb: FormBuilder,private common: CommonService) { }

  ngOnInit(): void {
    this.changeForm = this.fb.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
 
    },{
      validator: MustMatch('newPassword', 'confirmPassword'),
    });
  }


  btn_Continue(){
    this.submitted=true
    if(this.changeForm.valid){
      let data=this.changeForm.value
      data['token']=localStorage.getItem('accessToken')
      delete data['confirmPassword']

      this.https.httpPostWithHeader('resetPassword', data,false).subscribe((res: any) => {
        if(res['status']==200){
          this.common.presentsToast('success', 'top-end','Password reset Successfully')
        this.modalRef.hide();
        localStorage.clear();
        }
      })
    }else{
      this.changeForm.markAllAsTouched();
    }
  }
}
