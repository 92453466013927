<div class="auth-block">
    <button type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i
            class="mdi mdi-window-close text-white"></i></button>
    <div class="modal-body p-0">
        <div class="row g-0">
            <div class="col-md-6 p-0">
                <div class="auth-box">
                    <img src="assets/images/signup.jpg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-md-6">
                <form [formGroup]="signUpForm" (ngSubmit)="btn_signUp()">
                    <h3 class="text-center font-30 ff-playfair">
                        Sign Up
                    </h3>
                    <div class="auth-content ">
                        
                        <div class="py-scroll">
                        <div class="form-group">
                            <input type="text" placeholder="First Name" class="custom-control" formControlName="firstName" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            signUpForm.controls['firstName'].hasError('required')
                          " class="text-left">First Name is required.</mat-error>  
                        </div>
                        <div class="form-group">
                            <input type="text" placeholder="Last Name" class="custom-control" formControlName="lastName" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            signUpForm.controls['lastName'].hasError('required')
                          " class="text-left">Last Name is required.</mat-error>  
                        </div>
                        <div class="form-group">
                            <input type="text" placeholder="Email" class="custom-control" formControlName="email" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            signUpForm.controls['email'].hasError('required')
                          " class="text-left">Email is required.</mat-error>  
                        </div>
                        <div class="form-group">
                            <input type="password" placeholder="Password" class="custom-control" formControlName="password" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            signUpForm.controls['password'].hasError('required')
                          " class="text-left">Password is required.</mat-error>  
                        </div>
                        <div class="form-group">                           
                            <input type="password" placeholder="Confirm Password" class="custom-control" formControlName="confirmPassword" appNoSpaceAtFirst> 
                            <mat-error *ngIf="
                            submitted &&
                            signUpForm.controls['confirmPassword'].hasError('required')
                          " class="text-left">Password is required.</mat-error>  
                            <mat-error *ngIf="
                            submitted &&
                            signUpForm.controls['confirmPassword'].hasError('mustMatch')
                          " class="text-left">Password must match.</mat-error>                 
                        </div> 
    
                        <button class="btn btn-primary px-3 text-white w-100 rounded-pill p-t-10 p-b-10"
                           >Sign Up</button>
                        <h5 class="text-center m-0 pt-4 "><a href="javascript:void()" class="text-secondary"
                                (click)="openPhoneSignup()">Continue with Phone</a></h5>
                        <h5 class="text-secondary text-center m-0 pt-3">or connect with</h5>
                        <div class="row justify-content-center pt-3">
                            <div class="col-3 text-center" (click)="signInWithFB()">
                                <a href="javascript:void()">
                                    <figure class="social-icon">
                                        <img src="assets/images/facebook.png" alt="facebook">
                                    </figure>
                                </a>
                            </div>
                            <div class="col-3 text-center" (click)="signInWithGoogle()">
                                <a href="javascript:void()">
                                    <figure class="social-icon ">
                                        <img src="assets/images/google.png" alt="google">
                                    </figure>
                                </a>
                            </div>
                            <div class="col-3 text-center" (click)="openAppleAuthWindow()">
                                <a href="javascript:void()">
                                    <figure class="social-icon ">
                                        <img src="assets/images/apple.png" alt="apple">
                                    </figure>
                                </a>
                            </div>
                        </div>
                        <h5 class="text-center text-secondary ">Already have an account?<a href="javascript:void(0)"
                                class="text-primary m-1" (click)="openLoginEmail()">Sign in</a></h5>
                                </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>