<div class="auth-block">
    <button type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i class="mdi mdi-window-close text-white"></i></button>
    <div class="modal-body p-0">
        <div class="row g-0">
            <div class="col-md-6 p-0">
                <div class="auth-box"> 
                    <img src="assets/images/emai-check.jpg" alt="" class="img-fluid">           
                </div>
            </div>
            <div class="col-md-6">
                <div class="auth-content">
                    <div class="d-flex align-content-center  justify-content-center">
                        <figure>
                            <img src="assets/images/welcome.svg" alt="" id="my-image">
                        </figure>
                    </div>
                    <p class=" text-center font-30 m-0">Welcome To</p>
                    <h1 class="text-center ff-playfair">
                        Akunamatata
                    </h1>
                </div>
                <button class="btn btn-primary w-50 text-white p-b-10 p-t-10 font-20 rounded-pill">Go to Home Page</button>
            </div>
        </div>
    </div>
</div>
