import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutRoutingModule } from './layout-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatRadioModule } from '@angular/material/radio';
@NgModule({
  declarations: [HeaderComponent, FooterComponent, LayoutComponent, SidebarComponent],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    NgxSliderModule,
    BsDropdownModule.forRoot(),
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    MatRadioModule
  ],
  exports:[
    HeaderComponent, FooterComponent, LayoutComponent,SidebarComponent,
  ]
})
export class LayoutModule { }
