import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.scss']
})
export class WelcomeScreenComponent implements OnInit {

  constructor(public modalRef: BsModalRef, private modalService: BsModalService) { }

  ngOnInit(): void {
    document.getElementById('my-image').ondragstart = function() { return false; };
  }

}
