<div class="auth-block">
    <button type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i class="mdi mdi-window-close text-white"></i></button>
    <div class="modal-body p-0">
        <div class="row g-0">
            <div class="col-md-6 p-0">
                <div class="auth-box"> 
                    <img src="assets/images/forgot-img.jpg" alt="" class="img-fluid">           
                </div>
            </div>
            <div class="col-md-6">
                <form [formGroup]="changeForm" (ngSubmit)="btn_Continue()">
                    <div class="auth-content">
                        <div class="d-flex align-content-center  justify-content-center">
                            <figure>
                                <img src="assets/images/forgot-logo.svg" alt="">
                            </figure>
                        </div>
                        <h3 class="text-center font-30 ff-playfair">
                            Reset Password
                        </h3>
                        <div class="form-group">                           
                            <input type="password" placeholder="New Password" class="custom-control" formControlName="newPassword" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            changeForm.controls['newPassword'].hasError('required')
                          " class="text-left">New Passsword is required.</mat-error>            
                        </div>
                        <div class="form-group">                           
                            <input type="password" placeholder="Confirm Password" class="custom-control" formControlName="confirmPassword" appNoSpaceAtFirst>
                            <mat-error *ngIf="
                            submitted &&
                            changeForm.controls['confirmPassword'].hasError('required')
                          " class="text-left">Confirm Passsword is required.</mat-error>     
                          <mat-error *ngIf="
                            submitted &&
                            changeForm.controls['confirmPassword'].hasError('mustMatch')
                          " class="text-left">Password must match.</mat-error>          
                        </div>
                        <button class="btn btn-primary px-3 text-white w-100 rounded-pill p-t-10 p-b-10">Continue</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


