<div class="auth-block">
    <button type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i class="mdi mdi-window-close text-white"></i></button>
    <div class="modal-body p-0">
        <div class="row g-0">
            <div class="col-md-6 p-0">
                <div class="auth-box"> 
                    <img src="assets/images/forgot-img.jpg" alt="" class="img-fluid">           
                </div>
            </div>
            <div class="col-md-6">
                <div class="auth-content">
                    <h3 class="text-center font-30 ff-playfair mb-3">
                        Verify OTP
                    </h3>
                    <p  *ngIf="userDetails?.isLogin ==true" class="text-secondary text-center font-16 ">Enter 4 digit code sent on your registered
                        Email Id <a href="javascript:void(0)" class="text-primary">{{userDetails?.user?.email}}</a></p>
                   <p *ngIf="userDetails?.isPhoneLogin ==true" class="text-secondary text-center font-16 ">Enter 4 digit code sent on your registered
                            phone number <a href="javascript:void(0)" class="text-primary">{{userDetails?.user?.phone}}</a></p>
                            <p  *ngIf="userDetails?.isSignUp ==true" class="text-secondary text-center font-16 ">Enter 4 digit code sent on your registered
                                Email Id <a href="javascript:void(0)" class="text-primary">{{userDetails?.user?.email}}</a></p>
                           <p *ngIf="userDetails?.isPhoneSignUp ==true" class="text-secondary text-center font-16 ">Enter 4 digit code sent on your registered
                                    phone number <a href="javascript:void(0)" class="text-primary">{{userDetails?.user?.phone}}</a></p>
                                    <p  *ngIf="userDetails?.isForget ==true" class="text-secondary text-center font-16 ">Enter 4 digit code sent on your registered
                                        Email Id <a href="javascript:void(0)" class="text-primary">{{userDetails?.user?.email}}</a></p>
                                   <p *ngIf="userDetails?.isPhoneForget ==true" class="text-secondary text-center font-16 ">Enter 4 digit code sent on your registered
                                            phone number <a href="javascript:void(0)" class="text-primary">{{userDetails?.user?.phone}}</a></p>
                    <div class="form-group text-center">                           
                        <ng-otp-input  (onInputChange)="onOtpChange($event)" [config]="{length:4}"></ng-otp-input>     
                    </div>
                    <button class="btn btn-primary px-3 text-white w-100 rounded-pill p-t-10 p-b-10" (click)="onSubmit()">Continue</button>
                    <h5 class="text-center m-0 pt-4 "><a href="javascript:void()" class="text-primary font-16" (click)="resendOtp()">Resend Code</a></h5>
                </div>
            </div>
        </div>
    </div>
</div>

