<div class="auth-block">
    <button type="button" class="btn-custom-close  b-0 rounded-circle" aria-label="Close" (click)="modalRef?.hide()"><i class="mdi mdi-window-close text-white"></i></button>
    <div class="modal-body p-0" >
        <div class="row g-0">
            <div class="col-md-6 p-0">
                <div class="auth-box"> 
                    <img src="assets/images/emai-check.jpg" alt="" class="img-fluid">           
                </div>
            </div>
            <div class="col-md-6">
                <div class="auth-content cr-point" (click)="openPhoneotp()">
                    <div class="d-flex align-content-center  justify-content-center">
                        <figure>
                            <img src="assets/images/email.svg" alt="">
                        </figure>
                    </div>
                    <h3 class="text-center font-30 ff-playfair">
                        Check Your Email
                    </h3>
                    <p class="text-secondary text-center font-16 ">We’ve sent instructions on how to reset 
                        The password (also check the Spam folder)</p>
                </div>
            </div>
        </div>
    </div>
</div>
